// assets
import { IconDashboard, IconCirclePlus } from '@tabler/icons';

// constant
const icons = {
  IconDashboard,
  IconCirclePlus
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const entidadMenu = {
  id: 'entidadMenu',
  title: 'Entidad',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/entidad/default',
      icon: icons.IconDashboard,
      breadcrumbs: false
    }
  ]
};

export default entidadMenu;
