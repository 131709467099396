// index.js
import dashboard from './dashboard';
import Pages from './pages';
//import utilities from './utilities';
//import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, Pages()]
  // items: [dashboard, Pages(), utilities, other]
};

export default menuItems;
