import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
// import LoginRoutes from './LoginRoutes';
import PagesRoute from './PagesRoute';
// import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  // return useRoutes([{ path: '/', element: <PagesLanding /> }, AuthenticationRoutes, LoginRoutes, MainRoutes]);
  // return useRoutes([LoginRoutes, AuthenticationRoutes, MainRoutes, PagesRoute]);
  return useRoutes([MainRoutes, PagesRoute]);
}
