// imports
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
// import menuItem from 'menu-items';

import dashboard from 'menu-items/dashboard';
import Pages from 'menu-items/pages';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const { userData, loading } = useSelector((state) => state.user);
  const [devices, setDevices] = useState([]);
  const [expandedDevice, setExpandedDevice] = useState(null);

  useEffect(() => {
    if (!loading && userData) {
      const entities = userData.Entities || [];

      // Determinar la entidad a usar para el filtrado
      let targetEntity = null;
      if (userData.DefaultEntityId !== null) {
        targetEntity = entities.find((entity) => entity.EntityId === userData.DefaultEntityId);
      } else if (entities.length > 0) {
        targetEntity = entities.reduce((prev, current) => (prev.EntityId > current.EntityId ? prev : current));
      }

      // Si se encontró la entidad objetivo, extraer dispositivos activos
      if (targetEntity) {
        const extractedDevices =
          targetEntity.Localities?.flatMap((locality) => {
            return locality.Devices?.filter((device) => device.DeviceActive === 1) ?? [];
          }) || [];
        setDevices(extractedDevices);
      }
    }
  }, [loading, userData]);

  // Expandir menu dispositivo
  const handleExpand = (deviceCode) => {
    setExpandedDevice(expandedDevice === deviceCode ? null : deviceCode);
  };

  // menu-items.index.js
  const menuItem = {
    items: [dashboard]
  };

  if (devices.length > 0) {
    menuItem.items.push(Pages(devices, expandedDevice, handleExpand));
  }

  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
