import axios from 'axios';

const API_URL = 'https://diiotcore.com/api/v1/user/createGetUser';

export const fetchCreateGetUser = async (id, userName) => {
  try {
    const auth = {
      username: 'webUser',
      password: 'd$1e6!X2r2XL'
    };

    const data = {
      id,
      userName
    };

    const response = await axios.post(API_URL, data, {
      auth
    });

    if (response.status !== 200) {
      throw new Error(`Error fetching data. Status: ${response.status}`);
    }
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('Error de respuesta:', error.response.data);
    } else if (error.request) {
      console.error('Error de red:', error.request);
    } else {
      console.error('Error:', error.message);
    }
    throw error;
  }
};

export default fetchCreateGetUser;
