import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserProfile } from 'store/userActions';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Chip, ClickAwayListener, List, ListItemButton, ListItemIcon, ListItemText, Paper, Popper, Typography } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

// assets
import FactoryIcon from '@mui/icons-material/Factory';
import { IconCircle } from '@tabler/icons';

// Import fetchAssignDefaultEntity
import { fetchAssignDefaultEntity } from 'API/user/assignDefaultEntityUser';
import { useNavigate } from 'react-router-dom';

// ==============================|| PROFILE MENU ||============================== //

const EntitySection = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();
  const { userData, loading } = useSelector((state) => state.user);
  const [entidades, setEntidades] = useState([]);
  const [selectedEntityId, setSelectedEntityId] = useState(null);
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  useEffect(() => {
    if (!loading && userData) {
      // Extraer la lista de entidades con EntityId y EntityName
      const extractedEntities =
        userData.Entities?.map((entity) => ({
          EntityId: entity.EntityId,
          EntityName: entity.EntityName
        })) || [];

      setEntidades(extractedEntities);

      if (userData.DefaultEntityId !== null) {
        setSelectedEntityId(userData.DefaultEntityId);
      } else if (extractedEntities.length > 0) {
        const maxEntityId = Math.max(...extractedEntities.map((entity) => entity.EntityId));
        setSelectedEntityId(maxEntityId);
      }
    }
  }, [loading, userData]);

  const handleSelectEntity = async (entityId) => {
    setSelectedEntityId(entityId);
    try {
      await fetchAssignDefaultEntity(userData.UserId, entityId);
      // Refrescar informacion de usuario para cargar nuevas pantallas
      dispatch(fetchUserProfile(userData.Id, userData.UserMail));
      navigate('/entidad/default');
    } catch (error) {
      console.error('Failed to assign default entity:', error);
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  if (entidades.length <= 1) {
    return null;
  }

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light
            }
          },
          '& .MuiChip-label': {
            lineHeight: 0
          },
          width: 'auto', // Ajuste dinámico
          maxWidth: '300px' // Puedes ajustar este valor según sea necesario
        }}
        icon={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%', // Ajuste dinámico
              maxWidth: '300px', // Puedes ajustar este valor según sea necesario
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: 'black', // Color del texto
              fontSize: '1rem', // Tamaño de fuente similar a h4
              fontWeight: '500', // Grosor del texto
              padding: '0 10px' // Margen para el texto
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          >
            <span style={{ flexGrow: 1 }}>
              {selectedEntityId ? entidades.find((entity) => entity.EntityId === selectedEntityId)?.EntityName : null}
            </span>
            <FactoryIcon sx={{ color: '#1d325a', marginLeft: '8px' }} />
          </Box>
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                    <Box sx={{ p: 2 }}>
                      {/* ENTIDADES */}
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%'
                          },
                          '& .MuiListItemButton-root': {
                            mt: 0.5
                          }
                        }}
                      >
                        {entidades.map((entity) => (
                          <ListItemButton
                            key={entity.EntityId}
                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                            selected={entity.EntityId === selectedEntityId}
                            onClick={() => handleSelectEntity(entity.EntityId)}
                          >
                            <ListItemIcon>
                              {entity.EntityId === selectedEntityId ? (
                                <IconCircle stroke={1.5} size="1.3rem" style={{ fill: 'green' }} />
                              ) : (
                                <IconCircle stroke={1.5} size="1.3rem" />
                              )}
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">{entity.EntityName}</Typography>} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default EntitySection;
