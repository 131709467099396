import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import customizationReducer from './customizationReducer';
import userReducer from './userReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const rootReducer = combineReducers({
  customization: customizationReducer,
  user: userReducer
});

// ==============================|| REDUX - MAIN STORE ||============================== //

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persister = 'Free';
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export { store, persister };
