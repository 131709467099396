// ui-component/Logo.jsx
import React from 'react';

const Logo = () => {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/images/logoDIIOT.png`}
      alt="Logo"
      style={{ height: 'auto', width: '100%', maxWidth: '135px', maxHeight: '100%' }}
    />
  );
};

export default Logo;
