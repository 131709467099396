import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// ABM
// Entity
const NewEntity = Loadable(lazy(() => import('views/diiot/abm/entity/newEntity')));
const ModifyEntity = Loadable(lazy(() => import('views/diiot/abm/entity/modifyEntity')));
const ConfEntity = Loadable(lazy(() => import('views/diiot/abm/entity/entityConf')));

// Location
const NewLocation = Loadable(lazy(() => import('views/diiot/abm/location/newLocation')));
const ModifyLocation = Loadable(lazy(() => import('views/diiot/abm/location/modifyLocation')));
const ConfLocation = Loadable(lazy(() => import('views/diiot/abm/location/locationConf')));

// Device
const NewDevice = Loadable(lazy(() => import('views/diiot/abm/device/newDevice')));
const ModifyDevice = Loadable(lazy(() => import('views/diiot/abm/device/modifyDevice')));
const ConfDevice = Loadable(lazy(() => import('views/diiot/abm/device/deviceConf')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'entidad',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        },
        {
          path: 'nuevaEntidad',
          element: <NewEntity />
        },
        {
          path: 'modificarEntidad/:entityId',
          element: <ModifyEntity />
        },
        {
          path: 'configuracion',
          element: <ConfEntity />
        }
      ]
    },
    {
      path: 'locacion',
      children: [
        {
          path: 'nuevaLocacion',
          element: <NewLocation />
        },
        {
          path: 'modificarLocacion/:locationId',
          element: <ModifyLocation />
        },
        {
          path: 'configuracion',
          element: <ConfLocation />
        }
      ]
    },
    {
      path: 'dispositivo',
      children: [
        {
          path: 'nuevoDispositivo',
          element: <NewDevice />
        },
        {
          path: 'modificarDispositivo/:deviceCode',
          element: <ModifyDevice />
        },
        {
          path: 'configuracion',
          element: <ConfDevice />
        }
      ]
    }
  ]
};

export default MainRoutes;
