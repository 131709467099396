import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchUserProfile } from 'store/userActions';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider, Box, CircularProgress } from '@mui/material';
import Routes from 'routes';
import themes from 'themes';
import NavigationScroll from 'layout/NavigationScroll';

const App = () => {
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);
  const { isLoading, isAuthenticated, loginWithRedirect, user } = useAuth0();

  useEffect(() => {
    if (!isLoading && isAuthenticated && user) {
      // Despacha la acción para obtener el perfil del usuario
      dispatch(fetchUserProfile(user.sub, user.email));
    }
  }, [isLoading, isAuthenticated, user, dispatch]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
      return <div>Redirecting to login...</div>;
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
