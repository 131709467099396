// userActions.js
// APIs
import { fetchCreateGetUser } from 'API/user/createGetUser';
import { fetchGetUserData } from 'API/user/getUser';

export const fetchUserProfile = (sub, name) => {
  return async (dispatch) => {
    dispatch({ type: 'USER_PROFILE_REQUEST' });

    try {
      // Aquí se asume que tienes una API para obtener datos del usuario
      const data = await fetchCreateGetUser(sub, name);

      dispatch({ type: 'USER_PROFILE_SUCCESS', payload: data });

      // Llama a la segunda API con los datos del perfil
      dispatch(fetchUserData(data.UserId));
    } catch (error) {
      dispatch({ type: 'USER_PROFILE_FAILURE', payload: error.message });
    }
  };
};

export const fetchUserData = (userId) => {
  return async (dispatch) => {
    dispatch({ type: 'USER_DATA_REQUEST' });

    try {
      // Segunda API que necesita userId
      const data = await fetchGetUserData(userId);

      dispatch({ type: 'USER_DATA_SUCCESS', payload: data });
    } catch (error) {
      dispatch({ type: 'USER_DATA_FAILURE', payload: error.message });
    }
  };
};
